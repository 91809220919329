const loadGoogleMaps = () => {
    return new Promise((resolve, reject) => {
        if (window.loadedMaps === undefined) {
        // *************************************************************** //
        // Test key
        // *************************************************************** //
        // const googleKey = "AIzaSyAPQlH0tzqc9Jd9d29tqOJusKmlW3ch0d8"
        // *************************************************************** //
        // Public key
            const googleKey = 'AIzaSyCzHdZyLNiO4ZWEqVG9INGJivlDojmVgj8';

            const src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`;
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = src;
            script.onload = () => { return resolve(true); };
            document.body.appendChild(script);

            window.loadedMaps = true;
            return;
        }

        // Delay loading of 2n+ maps to avoid duplicate script errors.
        // Unsure of a better way to do this.
        if (window.loadedMaps === true) {
            setTimeout(() => { return resolve(true); }, 3000);
        }
    });
};

const mapStyles = [
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi',
        elementType: 'geometry.fill',
        stylers: [{ hue: '#BCE1C7' }, { saturation: -77 }, { lightness: 13 }],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'all',
        elementType: 'all',
        stylers: [{ hue: '#BCE1C7' }, { saturation: -49 }],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#BCE1C7',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#bee3f8',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#444444',
            },
            {
                visibility: 'on',
            },
        ],
    },
];

const mapIconSvg = ({ type, colour }) => {
    let path = '',
        scale = 1;
    const scaledSize = { w: 30, h: 42 },
        labelOrigin = { x: 15, y: 15 },
        colourOriginal = colour != '' ? colour : '#2b6cb0';
    path = 'M30,15.1c0,2.8-0.7,5.4-2,7.6h0l-0.1,0.2c-0.1,0.2-0.2,0.3-0.3,0.5L16.7,41c-0.8,1.3-2.6,1.3-3.4,0L2.4,23.4 c-0.1-0.2-0.2-0.3-0.3-0.5L2,22.7h0c-1.3-2.2-2-4.8-2-7.6C0,6.7,6.7,0,15,0C23.3,0,30,6.7,30,15.1z M15,22c3.3,0,6-2.7,6-6.1 c0-3.4-2.7-6.1-6-6.1s-6,2.7-6,6.1C9,19.3,11.7,22,15,22z';

    switch (type) {
        case 'current':
            (scale = 0.85),
            (path = 'M30,15.1c0,2.8-0.7,5.4-2,7.6h0l-0.1,0.2c-0.1,0.2-0.2,0.3-0.3,0.5L16.7,41c-0.8,1.3-2.6,1.3-3.4,0L2.4,23.4 c-0.1-0.2-0.2-0.3-0.3-0.5L2,22.7h0c-1.3-2.2-2-4.8-2-7.6C0,6.7,6.7,0,15,0C23.3,0,30,6.7,30,15.1z M15,22c3.3,0,6-2.7,6-6.1 c0-3.4-2.7-6.1-6-6.1s-6,2.7-6,6.1C9,19.3,11.7,22,15,22z');

            break;

        case 'enlargedCurrent':
            (scale = 1.15),
            (path = 'M30,15.1c0,2.8-0.7,5.4-2,7.6h0l-0.1,0.2c-0.1,0.2-0.2,0.3-0.3,0.5L16.7,41c-0.8,1.3-2.6,1.3-3.4,0L2.4,23.4 c-0.1-0.2-0.2-0.3-0.3-0.5L2,22.7h0c-1.3-2.2-2-4.8-2-7.6C0,6.7,6.7,0,15,0C23.3,0,30,6.7,30,15.1z M15,22c3.3,0,6-2.7,6-6.1 c0-3.4-2.7-6.1-6-6.1s-6,2.7-6,6.1C9,19.3,11.7,22,15,22z');

            break;

        case 'flat':
            (scale = 0.85),
            (path = 'M30,15.3c0,2.8-0.8,5.3-2,7.5l0,0L27.8,23c0,0.2-0.2,0.4-0.2,0.4L16.8,41.1c-0.8,1.2-2.6,1.2-3.4,0L2.6,23.4 C2.4,23.2,2.4,23,2.2,23L2,22.8l0,0c-1.2-2.2-2-4.9-2-7.5C0,6.9,6.8,0,15,0C23.2,0,30,6.9,30,15.3z');

            break;

        case 'enlargedFlat':
            (scale = 1.15),
            (path = 'M30,15.3c0,2.8-0.8,5.3-2,7.5l0,0L27.8,23c0,0.2-0.2,0.4-0.2,0.4L16.8,41.1c-0.8,1.2-2.6,1.2-3.4,0L2.6,23.4 C2.4,23.2,2.4,23,2.2,23L2,22.8l0,0c-1.2-2.2-2-4.9-2-7.5C0,6.9,6.8,0,15,0C23.2,0,30,6.9,30,15.3z');

            break;
    }

    return {
        path,
        scale,
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, scaledSize.h),
        labelOrigin: new google.maps.Point(labelOrigin.x, labelOrigin.y),
        fillColor: colourOriginal,
        fillOpacity: 1,
        strokeColor: colourOriginal,
        strokeWeight: 0,
    };
};

const markerLabels = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];


export {
    // debounce,
    loadGoogleMaps,
    mapIconSvg,
    markerLabels,
    // isDev,
    mapStyles,
};
