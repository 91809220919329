/* eslint no-use-before-define: 0 */ // --> OFF

import $ from 'jquery';
import {
    loadGoogleMaps, mapStyles, mapIconSvg, markerLabels,
} from './../utils';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import 'regenerator-runtime/runtime';

const CLASSNAME = {
    activeLocation: 'is-active',
    activeLocationDetail: 'is-active',
};

const font = {
    family: 'Arial, sans-serif',
    size: 12,
};

let globalLocationsMarkersWithCheckedToggles = [];
let globalMarkerColour = '#2b6cb0';
// let headerStyleEl = document.head.appendChild(document.createElement("style"))

const simpleInfoWindowTemplate = ({ url, title, address }) => {
    return `<div class="info-window__container">
                <div class="info-window__content">
                    <div class="info-window__title">
                        <a href="${url}" class="info-window__link">${title}</a>
                    </div>
                    <div class="info-window__item">${address}</div>
                </div>
            </div>`;
};

const detailInfoWindowTemplate = ({
    url, title, address, image, type, openDayTime, facebook, instagram, tags,
}) => {
    return `<div class="info-window__container">
                ${image ? `<div class="info-window__image w-1/4">${image}</div>` : ''}
                <div class="info-window__content w-3/4">
                    <div class="info-window__title leading-none">${title}</div>
                    ${address ? `<div class="info-window__item mb-2">${address}</div>` : ''}
                    ${type ? `<div class="info-window__item"><span class="text-primary-900 font-semibold">Playgroup type:</span> <div>${type}</div></div>` : ''}
                    ${openDayTime != '' ? `<div class="info-window__item"><span class="text-primary-900 font-semibold">Open day:</span> ${openDayTime}</div>` : ''}
                    ${tags ? `<div class="info-window__item">${tags}</div>` : ''}
                    ${facebook || instagram ? `<div class="info-window__item mt-4">
                        ${facebook ? `<a class="inline-block" href="${facebook}" target="_blank">
                            <span class="icon w-6 h-5 block" aria-hidden="true">
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                </svg>
                            </span>
                        </a>` : ''}
                        ${instagram ? `<a class="ml-4 inline-block" href="${instagram}" target="_blank">
                            <span class="icon w-6 h-5 block" aria-hidden="true">
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                </svg>
                            </span>
                        </a>` : ''}
                    </div>` : ''}
                </div>
            </div>`;
};

const smoothZoom = (map, max, cnt) => {
    if (cnt >= max) { return; }
    const z = google.maps.event.addListener(map, 'zoom_changed', () => {
        google.maps.event.removeListener(z);
        smoothZoom(map, max, cnt + 1);
    });
    setTimeout(() => {
        map.setZoom(cnt);
    }, 80); // 80ms is what I found to work well on my system -- it might not work well on all systems
};

const setBoundries = (
    map,
    markersArray,
    setBounds = true,
    markerClusterer = null,
) => {
    if (markersArray.length === 0) { return; }

    const bounds = setBounds && new google.maps.LatLngBounds();

    if (markerClusterer) {
        // clear marker clusterer
        markerClusterer.clearMarkers();

        // reset marker clusterer with new markers
        markerClusterer.addMarkers(markersArray);
    }

    if (setBounds) {
        for (let i = 0; i < markersArray.length; i++) {
            if (markersArray[i].lat < 0 && markersArray[i].lng > 0) {
                bounds.extend(markersArray[i].getPosition());
            }
        }

        // Don't zoom in too far on only one marker
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            const extendPoint1 = new google.maps.LatLng(
                bounds.getNorthEast().lat() + 0.001,
                bounds.getNorthEast().lng() + 0.001,
            );
            const extendPoint2 = new google.maps.LatLng(
                bounds.getNorthEast().lat() - 0.001,
                bounds.getNorthEast().lng() - 0.001,
            );
            bounds.extend(extendPoint1);
            bounds.extend(extendPoint2);
        }

        map.fitBounds(bounds);
    }
};

const createSidebar = ({
    map,
    markers,
    sidebarEl,
    mapIcons,
    mapSidebarType,
    markerClusterer,
    markerColour = null,
}) => {
    if (mapSidebarType == 'toggles') {
        sidebarEl.on('click', '[data-map-location] input:checkbox', () => {
            const checkedToggles = [];
            const locationsWithCheckedToggle = [];
            const locationsMarkersWithCheckedToggles = [];
            const checkedElements = document.querySelectorAll(
                '[data-map-location] input:checked',
            );

            checkedElements.forEach((elem) => {
                if (checkedToggles.indexOf(elem.value) == -1) {
                    checkedToggles.push(elem.value);
                }
            });

            // Hide all markers when checked
            for (let i = 0; i < markers.length; i++) {
                markers[i].setVisible(false);
            }

            checkedToggles.forEach((handle) => {
                for (let i = 0; i < markers.length; i++) {
                    if (markers[i].toggle.indexOf(handle) != -1) {
                        if (
                            locationsWithCheckedToggle.indexOf(
                                markers[i].handle,
                            ) == -1
                        ) {
                            locationsWithCheckedToggle.push(markers[i].handle);
                            locationsMarkersWithCheckedToggles.push(markers[i]);
                            markers[i].setVisible(true);
                        }
                    }
                }
            });

            globalLocationsMarkersWithCheckedToggles = locationsMarkersWithCheckedToggles;

            setBoundries(
                map,
                locationsMarkersWithCheckedToggles,
                true,
                markerClusterer,
            );
        });
    }

    if (mapSidebarType == 'list') {
        const markerImageOptions = {
            flat: mapIconSvg({
                type: 'flat',
                colour: globalMarkerColour,
            }),
            enlargedFlat: mapIconSvg({
                type: 'enlargedFlat',
                icon: globalMarkerColour,
            }),
        };

        sidebarEl.on('click', '[data-map-location]', function() {
            $.each(markers, (index) => {
                if (index != 0) {
                    markers[index].setIcon(markerImageOptions.flat);
                }
            });

            // Get list of locations for this map
            $(sidebarEl)
                .find('[data-map-location]')
                .removeClass(CLASSNAME.activeLocation);

            // Add the active class to the selected sidebar location
            $(`#${$(this).attr('id')}`).addClass(CLASSNAME.activeLocation);

            // $('html,body').animate({ scrollTop: $('[data-map-container]').offset().top }, 'slow');

            // Trigger a click on the marker
            google.maps.event.trigger(
                markers[
                    $(this)
                        .attr('id')
                        .split('-')[1] - 1
                ],
                'click',
            );
        });
    }
};

// This function picks up the click and opens the corresponding info window
const createMarkers = ({
    map,
    locations,
    mapIcons,
    sidebarEl,
    mapSidebarType,
    mapLabelType,
    uid,
}) => {
    const markers = [];

    let currentInfoWindow = null;

    mapSidebarType = locations.length > 1 ? mapSidebarType : 'single';

    $.each(locations, (index, location) => {

        const { locationName } = location,
            { locationHandle } = location,
            locationLinkId = `${uid}Location-${index + 1}`,
            { locationImage } = location,
            { locationTags } = location,
            { locationUrl } = location,
            { locationLat } = location,
            { locationLng } = location,
            { locationType } = location,
            { locationOpenDayTime } = location,
            { locationAddress } = location,
            { locationColour } = location,
            { locationZoom } = location,
            { locationToggleHandle } = location,
            { locationFacebook } = location,
            { locationInstagram } = location;

        const latInt = parseFloat(locationLat),
            longInt = parseFloat(locationLng),
            latLng = new google.maps.LatLng(latInt, longInt);

        let label = null;

        if (mapLabelType == 'text') {
            label = markerLabels[index];
        }

        if (mapLabelType == 'id') {
            label = `${index + 1}`;
        }

        const labelConfig = {
            text: label,
            color: sidebarEl.length > 0 ? 'white' : 'rgba(255, 255, 255, 0)',
            fontSize: `${font.size}`,
            fontFamily: `${font.family}`,
        };

        // Default icon config
        let markerImageOptions = {
                current: mapIconSvg({
                    type: 'current',
                    colour: locationColour,
                }),
                enlargedCurrent: mapIconSvg({
                    type: 'enlargedCurrent',
                    colour: locationColour,
                }),
            },
            icon = markerImageOptions.current,
            enlargedIcon = markerImageOptions.enlargedCurrent;

        if (mapSidebarType == 'list') {
            (globalMarkerColour = locationColour),
            (markerImageOptions = {
                flat: mapIconSvg({
                    type: 'flat',
                    colour: globalMarkerColour,
                }),
                enlargedFlat: mapIconSvg({
                    type: 'enlargedFlat',
                    colour: globalMarkerColour,
                }),
            }),
            (icon = markerImageOptions.flat),
            (enlargedIcon = markerImageOptions.enlargedFlat);
        }

        const marker = new google.maps.Marker({
            index,
            handle: locationHandle,
            map,
            position: latLng,
            lat: latInt,
            lng: longInt,
            id: locationLinkId,
            animation: google.maps.Animation.DROP,
            icon,
            title: locationName,
            address: locationAddress,
            type: locationType,
            openDayTime: locationOpenDayTime,
            facebook: locationFacebook,
            instagram: locationInstagram,
            url: locationUrl,
            label: label && labelConfig,
            toggle: locationToggleHandle,
        });

        console.log(marker);

        console.log(locationOpenDayTime);

        const infowindow = new google.maps.InfoWindow({
            content: detailInfoWindowTemplate({
                url: locationUrl,
                title: locationName,
                address: locationAddress,
                type: locationType,
                openDayTime: locationOpenDayTime,
                facebook: locationFacebook,
                instagram: locationInstagram,
                image: locationImage,
                tags: locationTags,
            }),
            disableAutoPan: true,
            // pixelOffset: new google.maps.Size(200, 0),
        });

        const target = document.getElementById(locationLinkId);

        marker.addListener('click', (e) => {
            // Close other previously opened infowindow
            if (currentInfoWindow != null) {
                currentInfoWindow.close();
                currentInfoWindow = null;
            }

            if (sidebarEl.length > 0 && mapSidebarType == 'list') {
                $('[data-map-location]').removeClass(CLASSNAME.activeLocation);
            }

            smoothZoom(map, locationZoom ? locationZoom : 20, map.getZoom());
            map.panTo(latLng);
            map.panBy(0, -0.02);

            // console.log(locationZoom)

            if (sidebarEl.length > 0 && mapSidebarType == 'list') {
                target.classList.add(CLASSNAME.activeLocation);
                target.parentNode.scrollTop = target.offsetTop;
            }

            marker.setIcon(enlargedIcon);

            infowindow.open({
                anchor: marker,
                map,
                shouldFocus: true,
            });
            currentInfoWindow = infowindow;
        });

        google.maps.event.addListener(infowindow, 'domready', () => {
            const iwOuter = $('.gm-style-iw'),
                iwBackground = iwOuter.prev();

            iwOuter.parent().addClass('info-window');
            iwBackground
                .children(
                    ':nth-child(1), :nth-child(2), :nth-child(3), :nth-child(4)',
                )
                .css({ display: 'none' });
        });

        google.maps.event.addListener(infowindow, 'closeclick', () => {
            $('[data-map-location]').removeClass(CLASSNAME.activeLocation);

            if (globalLocationsMarkersWithCheckedToggles.length > 0) {
                setBoundries(
                    map,
                    globalLocationsMarkersWithCheckedToggles,
                    true,
                    null,
                );
            } else {
                setBoundries(map, markers, true, null);
            }

            marker.setIcon(icon);
        });

        markers.push(marker);
    });

    return markers;
};

const init = (
    mapInstance,
    {
        mapClusterMarker,
        mapLocations,
        mapIcons,
        mapZoom,
        mapSuburb,
        mapSidebarType,
        mapLabelType,
        mapMarkerColour = null,
    },
    uid,
    global,
) => {

    const lat = mapSuburb.lat != '' ? mapSuburb.lat : -42.095328,
        lng = mapSuburb.lng != '' ? mapSuburb.lng : 146.645404,
        latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng)),
        northEast = new google.maps.LatLng(-39.489802, 148.559548),
        southWest = new google.maps.LatLng(-43.714163, 143.552106),
        tasmaniaBounds = new google.maps.LatLngBounds(southWest, northEast),
        zoom = mapZoom != '' ? Number(mapZoom) : 14;

    mapLocations;

    const locations = mapLocations,
        clusterOptions = {
            styles: [
                {
                    textColor: 'white',
                    url: mapClusterMarker,
                    height: 50,
                    width: 50,
                    anchorText: [16, 0],
                    // anchorIcon: [45, 46],
                },
            ],
            averageCenter: true,
            enableRetinaIcons: true,
            gridSize: 30,
        },
        styles = mapStyles;

    const sidebarEl = $(mapInstance)
        .parent()
        .parent()
        .parent()
        .find('[data-map-sidebar]');

    const map = new google.maps.Map(mapInstance, {
        center: latLng,
        zoom,
        styles,
        streetViewControl: false,
        overviewMapControl: false,
        mapTypeControl: false,
        scaleControl: false,
        bounds: tasmaniaBounds,
        strictBounds: true,
        componentRestrictions: { country: 'au' },
    });

    // Markers
    const markers = createMarkers({
        map,
        locations,
        mapIcons,
        sidebarEl,
        mapSidebarType,
        mapLabelType,
        uid,
    });

    const markerClusterer = new MarkerClusterer(map, markers, clusterOptions);

    // Setting markers in viewport
    setBoundries(map, markers, sidebarEl.length > 0 && true);

    // Creating side bar and toggle
    createSidebar({
        map,
        markers,
        sidebarEl,
        mapIcons,
        mapSidebarType,
        markerClusterer,
        mapMarkerColour,
    });
};

class googleMaps {
    constructor(element) {
        const uid = element.getAttribute('data-map-container');
        // if (!uid in window) { return; }

        this.map = element;
        init(this.map, window[uid], uid, this);
    }
}

document.addEventListener('DOMContentLoaded', async() => {
    const tabMapElements = document.querySelectorAll('[data-map-container]');

    if (tabMapElements.length === 0) { return; }

    // Wait for the map libraries to load
    await loadGoogleMaps();

    // Create tab maps for each map on the page
    tabMapElements.forEach((element) => { return new googleMaps(element); });
});
